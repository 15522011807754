import { useContext } from 'react';
import {observer} from "mobx-react";
import {action} from "mobx";
import classNames from "classnames";
import styles from "./styles/Logo.module.scss";
import FontAwesome from "../utilities/FontAwesome";
import { StoreContext } from "../../stores/StoreLoader";
import {useMachine} from "@xstate/react";
import {ImageUploadMachine} from "../machines/ImageUploadMachine";
import SchoolBlocksLoadingIndicator from "../utilities/SchoolBlocksLoadingIndicator";
import {compressImage} from "../../utils/DataUtilities";
import NotificationManager from "../notifications/NotificationManager";

const Logo = observer((props: {
    logoClassName?: string,
    disabled?: boolean,
}) => {
    const [current, send] = useMachine(ImageUploadMachine);
    const {organizationStore, userStore, gridStore} = useContext(StoreContext);

    const logo = organizationStore.currentOrganization.logo;

    const orgLogoClassName = classNames({
        [styles.orgLogo]: true,
        [styles.orgLogoDisabled]: props.disabled,
        [styles.orgLogoUpload]: userStore.isAdmin && !logo,
        [styles.orgLogoUploadLoading]: current.value !== 'IDLE' && current.value !== 'SUCCESS',
    });

    async function saveSettings(file, name) {
        send('UPLOAD');
        const logo = await organizationStore.imageUpload('logo', file, name);
        if (logo) {
            send('SUCCEED');
            action(() => organizationStore.currentOrganization.logo = logo.media_url)();
            if (organizationStore.organization.id === organizationStore.currentOrganization.id) {
                action(() => organizationStore.organization.logo = logo.media_url)();
            }
        } else {
            send("FAIL");
        }
    }

    async function handleLogoChange(file) {
        // check for file because this can be triggered if user selects input but then cancels and no file is selected
        if (file) {
            send('PROCESS');
            try {
                file = await compressImage(file,1)
                await saveSettings(file, file.name);
            } catch (error) {
                send('FAIL')
                NotificationManager.error(error.message, undefined, 3000)
            }
        }
    }


    let logoBlock;

    if (logo && userStore.isAdmin && (current.value === 'IDLE' || current.value === 'SUCCESS')) {
        logoBlock = <>
            <label htmlFor={'logo-upload'}><img
                alt={`${organizationStore.currentOrganization.title} Logo`}
                src={logo}/></label>
            <input
                id={'logo-upload'}
                name={'logo-upload'}
                type={'file'}
                onChange={e => {
                    if (e.target.files) {
                        handleLogoChange(e.target.files[0])
                    }
                }}
                accept={'image/*'}
            />
        </>
    } else if (logo && !userStore.isAdmin) {
        logoBlock = <img alt={`${organizationStore.organization.title} Logo`}
                         src={logo}/>;
    } else if (userStore.isAdmin) {
        let content;
        switch(current.value) {
            case 'IDLE':
                content = <div className={styles.uploadContainer}><FontAwesome prefix={'fas'} name={'fa-upload'} /> Upload Logo</div>;
                break;
            case 'FAILURE':
                content = <div className={styles.uploadContainer}><FontAwesome prefix={'fas'} ariaHidden={true} name={'fa-exclamation-triangle'}/> An error occurred!</div>;
                break;
            case 'PROCESSING':
                content = <div className={styles.uploadContainer}><SchoolBlocksLoadingIndicator background={'white'} /> Processing...</div>;
                break;
            case 'UPLOADING':
                content = <div className={styles.uploadContainer}><SchoolBlocksLoadingIndicator background={'white'} /> Uploading...</div>;
                break;
        }
        logoBlock = <>
            <label htmlFor={'logo-upload'} aria-label={'Add a Logo'}>
                {content}
            </label>
            <input
                id={'logo-upload'}
                name={'logo-upload'}
                type={'file'}
                onChange={e => {
                    if (e.target.files) {
                        handleLogoChange(e.target.files[0])
                    }
                }}
                accept={'image/*'}
            />
        </>
    }

    return (
        <div className={`${orgLogoClassName} ${props.logoClassName}`}>
            {logoBlock}
        </div>
    );
});

export default Logo;
